<template>
    <div class="app-container">
        <user-list
            v-loading="loading.state"
            :users="mappedUsers"
            :element-loading-text="loading.message"
            plan_heading="Expired plan"
            :show_expired="true"
            :css="{height: 'calc(100vh - 140px)'}"
            :scroll="true"
            :new_window="true"
            @new-claim="newClaim"
        />

        <claim-modal
            :categories="user_categories"
            :data="user_plan"
            :user="user"
        />
    </div>
</template>

<script>
import UserList from '@/views/components/UserList';
import ClaimModal from '@/views/components/ClaimModal';
import moment from 'moment';
import _ from 'lodash';

export default {
    name: 'expiry',
    components: {
        UserList,
        ClaimModal,
    },
    props: {
        view: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            users: [],
            userQueue: null, // users pending processing
            loading: {
                state: true,
                message: null,
            },
            loadingMessage: null,

            // new claims
            user_categories: [],
            user_plan: [],
            user: {},
            raw_categories: [],
        };
    },
    computed: {
        mappedUsers() {
            return this.users
                .filter((user) => user.plan)
                .map((user) => ({
                    id: user.id,
                    name: user.name,
                    code: user.code,
                    plans: [user.plan],
                    activePlan: user.plan,
                }));
        },
    },
    async mounted() {
        this.$bind(
            'users',
            this.$fire
                .collection('expired_cache')
                .orderBy('expired_on', 'desc'),
            {maxRefDepth: 1}
        );

        // categories for new claim modal
        this.$bind(
            'raw_categories',
            this.$fire.collection('categories').orderBy('order'),
            {maxRefDepth: 0}
        );

        this.loading.state = false;
    },
    methods: {
        newClaim(user, activePlan) {
            this.user = user;
            this.user_plan = activePlan.data.map((item) => {
                return {category: item.category.path, budget: item.budget.path};
            });

            // get assigned categories for user
            const plan_categories = activePlan.data.map((item) => {
                return item.category.path;
            });
            this.user_categories = this.raw_categories
                .filter((raw_cat) => {
                    return plan_categories.includes(`categories/${raw_cat.id}`);
                })
                .sort((a, b) => {
                    return a.order - b.order;
                });
            this.$bus.$emit('claim-modal', {user});
        },
    },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.pagination-container {
    background-color: white;
    margin: 0;
    padding: 20px;
    border: 1px solid #ebeef5;
    border-top: 0;
    display: flex;
    justify-content: center;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}

.filter-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    div {
        margin-right: 10px;
    }

    .new-user {
        float: right;
    }
}

.full-width {
    width: 100%;
}

.el-tag {
    width: 100%;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 13px;
    font-weight: 500;
}
</style>
