var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("user-list", {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading.state,
            expression: "loading.state",
          },
        ],
        attrs: {
          users: _vm.mappedUsers,
          "element-loading-text": _vm.loading.message,
          plan_heading: "Expired plan",
          show_expired: true,
          css: { height: "calc(100vh - 140px)" },
          scroll: true,
          new_window: true,
        },
        on: { "new-claim": _vm.newClaim },
      }),
      _c("claim-modal", {
        attrs: {
          categories: _vm.user_categories,
          data: _vm.user_plan,
          user: _vm.user,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }